import * as React from "react"
import { useContext } from "react"
import { graphql } from "gatsby"

import {
  Header,
  Mediation,
  Footer,
  BottomNavbar,
  NoAuth,
  NoAccess,
  Loader,
} from "../components/index"
import { Helmet } from "react-helmet"

import mockup from "../utils/data"

// Context
import UserContext from "../context/UserAuth"

const IndexPage = ({ data }) => {
  const { isAuthenticated, isAccess, isLoading } = useContext(UserContext) || {
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
  }

  console.log(data)

  return (
    <div>
      <Helmet>
        <title>Esteekey - Mediation</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        {isLoading && <Loader />}
        {!isAuthenticated ? (
          <NoAuth />
        ) : !isAccess ? (
          <NoAccess />
        ) : (
          <Mediation list={data?.mediations?.nodes || mockup.mediations} />
        )}
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query allRecipeUnit {
    mediations: allSanityMeditation {
      nodes {
        audio {
          asset {
            url
          }
        }
        title
        titleRU
        shortDescription
        id
      }
    }
  }
`

export default IndexPage
